<script>
import Vue from "vue";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import UserForm from "./forms/user-form.vue";
import CustomTable from "../../../components/tables/custom-table.vue";
import AddButton from "../../../components/buttons/add-button.vue";

import {
  authMethods
} from "@/state/helpers";

/**
 * Users Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    AddButton,
    UserForm,
    CustomTable
  },
  data() {
    return {
      modalId:'user-form-modal',
      pageHeader:{
        title: "Usuarios",
        breadcrumbs: [
          {
            text: "Usuarios",
            href: "/users",
            active: true
          }
        ],
      },
      table:{
        url:'/users',
        fields:[
          { key: "id", label: "Cod", sortable: true },
          { key: "name", label: "Nombre", sortable: true },
          { key: "email", label: "Correo", sortable: true },
        ]
      },
      selectedItemId:null      
    };
  },
  methods: {
    ...authMethods,
    showEditModal(item) {
      this.selectedItemId = item?.id || null;
      this.$bvModal.show(this.modalId);
    },
    onSuccess(){
      this.$refs.userTable.refresh();
    },
    async forgotUserPassword(user){
      try {

        const result = await Vue.swal({
          title: "Resetear contraseña",
          icon: "info",
          html:  "Seguro que desea restablecer la contraseña de este usuario?",
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Sí',
          cancelButtonText: 'Cancelar',
        });

        if (result.value) {
          await this.forgotPassword({
            email: user.email
          });
        }
        
      }
      catch (error) {
          console.log(error);
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <UserForm :modal-id="modalId" :id="selectedItemId" @onSuccess="onSuccess"></UserForm>
            <div class="text-right">
              <AddButton @click="showEditModal"></AddButton>
            </div>
            <CustomTable :url="table.url" :fields="table.fields" @edit="showEditModal" ref="userTable">
              <template v-slot:before-custom-actions="slotProps">
                <a
                    href="javascript:void(0);"
                    class="mr-3 text-primary"
                    v-b-tooltip.hover
                    title="Resetear contraseña"
                    @click="forgotUserPassword(slotProps.row?.item)"
                >
                    <i class="ri-rotate-lock-fill font-size-18"></i>
                </a>
              </template>
            </CustomTable>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>